<template>
  <v-container
    fluid
    class="pt-0"
  >
    <v-text-field
      id="municipios-search"
      v-model="search"
      append-icon="mdi-magnify"
      class="ml-auto mt-4 break-search"
      label="Procurar"
      hide-details
      single-line
      style="max-width: 250px"
    />
    <v-divider class="mt-10" />
    <v-data-table
      :headers="headers"
      :items="municipios"
      :search.sync="search"
      :mobile-breakpoint="800"
      :loading="loading"
      :hide-default-header="loading"
      :hide-default-footer="loading"
      no-data-text="Nenhum município disponível"
    >
      <template v-slot:[`item.municipio`]="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <div
              class="text-truncate-column"
              v-bind="attrs"
              v-on="on"
            >
              {{ item.municipio }}
            </div>
          </template>
          <span>{{ item.municipio }}</span>
        </v-tooltip>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import MunicipiosService from '@/services/MunicipiosService';

export default {
  name: 'MunicipiosDataTable',

  props: {
    month: {
      type: String
    },
    bdgdVersion: {
      type: Number
    }
  },

  data: () => ({
    search: '',
    municipios: [],
    loading: false,
    headers: [
      {
        text: 'COD_IBGE',
        value: 'cod_ibge'
      },
      {
        text: 'MUNICÍPIO',
        value: 'municipio'
      },
      {
        text: 'UF',
        value: 'uf'
      }
    ]
  }),

  created() {
    this.getMunicipios();
  },

  methods: {
    getMunicipios() {
      this.loading = true;
      MunicipiosService.getMunicipiosNaAreaConcessao(
        this.month,
        this.bdgdVersion
      )
        .then((response) => (this.municipios = response.data))
        .catch(() =>
          this.$toast.error('Erro ao recuperar os municípios.', '', {
            position: 'topRight'
          })
        )
        .finally(() => (this.loading = false));
    }
  },

  watch: {
    month() {
      this.getMunicipios();
    },
    bdgdVersion() {
      this.getMunicipios();
    },
    municipios() {
      this.$emit('itemsChanged', {
        items: this.municipios,
        numItems: this.municipios.length
      });
    }
  }
};
</script>
